// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   TAILWIND_MODE: 'build',
//   base_path: 'http://37.27.29.117:1337/api',
//   base_host: 'http://37.27.29.117:1337',
// };

export const environment = {
  production: false,
  TAILWIND_MODE: 'build',
  base_path: 'https://api.realtechtrans.com/api',
  base_host: 'https://admin.realtechtrans.com/',
  booking_path: 'https://api.realtechtrans.com/api/',
  mqttBrokerUrl: 'wss://mqtt.realtechtrans.com:8883',
  // base_path: 'https://37.27.29.117:1337/api',
  // base_host: 'https://37.27.29.117:1337',
};

// export const environment = {
//   production: false,
//   TAILWIND_MODE: 'build',
//   base_path: 'http://localhost:1337/api',
//   base_host: 'http://localhost:1337',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

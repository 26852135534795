import { Injectable, signal } from '@angular/core';
import Main from 'src/assets/js/main';
@Injectable({
  providedIn: 'root',
})
export class ThemeService extends Main {
  constructor() {
    super();
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toast: NgToastService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Unknown error occurred';

        if (error.error) {
          errorMessage = this.handleErrorCode(error.status);

          // Server-side errors
          // errorMessage = `Error message: ${error.error.message}`;
          // if (error.error.errors) {
          //   // Handle validation errors
          //   const validationErrors = error.error.errors;
          //   Object.keys(validationErrors).forEach(prop => {
          //     const propErrors = validationErrors[prop];
          //     errorMessage += `\n${prop}: ${propErrors.join(', ')}`;
          //   });
          // }
        } else if (error.status === 0) {
          // Network errors
          errorMessage = 'Unable to connect to the server';
        } else {
          // Client-side errors
          errorMessage = `Error status: ${error.status}\nError message: ${error.message}`;
        }
        this.toast.error({ detail: 'Error', summary: errorMessage, duration: 5000 });
        // console.error(errorMessage);
        return throwError(error);
      }),
    );
  }

  handleErrorCode(errorCode: any) {
    switch (errorCode) {
      case 400:
        return 'Handle error code 400 (Bad Request)';
      case 401:
        this.logout();

        return 'Looks like you logged out. Please Login Again!';
      case 403:
        return 'Handle error code 403 (Forbidden)';
      case 404:
        return 'Handle error code 404 (Not Found)';
      case 200:
        return 'Deleted Successfully';
      case 0:
        return 'Please do check your network connection and retry.';
      default:
        return 'Handle unrecognized error codes';
    }
  }

  logout() {
    console.log('Logged out');
    // TODO: Implement logout logic.
    // Clear any stored user session data or tokens.
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['auth/sign-in']);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, UnauthGuard } from './core/guards/AuthGuard';
import { ProgressBarService } from './core/component/progress/progress-bar.component.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProgressBarInterceptor } from './core/interceptor/progress-bar.interceptor.service';
import { ErrorInterceptor } from './core/interceptor/error.interceptor.service';
import { HeaderInterceptor } from './core/interceptor/header.interceptor.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { ErrorPageComponent } from './modules/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [UnauthGuard],
  },

  // {
  //   path: 'chat',
  //   loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
  // },
  // {
  //   path: 'apps',
  //   loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
  // },
  // {
  //   path: 'reports',
  //   loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
  // },
  { path: 'error/404', component: ErrorPageComponent },

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
  ],
  exports: [RouterModule],
  providers: [
    ProgressBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressBarInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    DatePipe,
  ],
})
export class AppRoutingModule {}

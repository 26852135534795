import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    const token = localStorage.getItem('token');
    if (token) {
      authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        }),
      });
    }
    return next.handle(authReq);
  }
}

<div
  class="min-h-screen flex flex-col items-center justify-center bg-gray-100 bg-cover bg-center"
  style="background-image: url('https://lineone.piniastudio.com/images/illustrations/ufo-bg.svg')">
  <div class="text-center">
    <div class="relative">
      <img src="https://lineone.piniastudio.com/images/illustrations/ufo.svg" alt="404 Alien" class="mx-auto h-64" />
    </div>
    <h1 class="text-4xl font-bold text-blue-600 mt-6">404</h1>
    <p class="text-lg text-gray-700 mt-2">Oops. This Page Not Found.</p>
    <p class="text-md text-gray-500 mb-6">The page you are looking for does not exist.</p>
    <a (click)="goBack()" class="mt-10 px-4 py-2 bg-blue-600 text-white rounded-md cursor-pointer">Go Back</a>
  </div>
</div>

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (isLoggedIn) {
      return true;
    }

    // Navigate to login page as user is not authenticated
    this.router.navigate(['auth/sign-in']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate {
  constructor(
    private router: Router,
    private location: Location,
  ) {}

  canActivate(): boolean {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (isLoggedIn) {
      // Redirect to dashboard or any other appropriate page
      // this.router.navigate(['dashboard']);
      this.location.back();
      return false;
    }

    return true;
  }
}

export const environment = {
  production: true,
  version: '1.0.0',
  sourceType: 'local-storage',
  googleMapsApiKey: 'AIzaSyBcwMM9Zz1uCi7ZubHLZsHLSsZpSq6NRhU',
  base_path: 'https://api.realtechtrans.com/api',
  base_host: 'https://admin.realtechtrans.com/',
  booking_path: 'https://api.realtechtrans.com/api/',
  mqttBrokerUrl: 'wss://mqtt.realtechtrans.com:8883',
  base_paths: 'https://api.realtechtrans.com/api/add-vehicle',
};

// export const environment = {
//   production: true,
//   version: '1.0.0',
//   sourceType: 'local-storage',
//   googleMapsApiKey: 'AIzaSyBcwMM9Zz1uCi7ZubHLZsHLSsZpSq6NRhU',
//   base_path: 'https://37.27.29.117:1337/api',
//   base_host: 'https://37.27.29.117:1337/',
// };

// export const environment = {
//   production: true,
//   version: '1.0.0',
//   sourceType: 'local-storage',
//   googleMapsApiKey: 'AIzaSyBcwMM9Zz1uCi7ZubHLZsHLSsZpSq6NRhU',
//   base_path: 'http://localhost:1337/api',
//   base_host: 'http://localhost:1337',
// };

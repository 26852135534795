// progress-bar.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private loading$ = new BehaviorSubject<boolean>(false);

  get loading(): boolean {
    return this.loading$.value;
  }

  setLoading(loading: boolean) {
    this.loading$.next(loading);
  }
}
